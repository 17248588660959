import { useRouter } from 'next/router'
import Link, { LinkProps } from 'next/link'
import React, { useState, useEffect, ReactElement } from 'react'
import classnames from 'classnames'

type ActiveLinkProps = LinkProps & {
  children: ReactElement | string | null
  activeClassName: string
  nonActiveClassName: string
  className?: string
}

const ActiveLink = ({
  children,
  activeClassName,
  nonActiveClassName,
  className: defaultClassname,
  ...props
}: ActiveLinkProps) => {
  const { asPath, isReady } = useRouter()

  const [className, setClassName] = useState(defaultClassname)

  useEffect(() => {
    // Check if the router fields are updated client-side
    if (isReady) {
      // Dynamic route will be matched via props.as
      // Static route will be matched via props.href
      const linkPathname = new URL((props.as || props.href) as string, location.href).pathname

      // Using URL().pathname to get rid of query and hash
      const activePathname = new URL(asPath, location.href).pathname

      const isActive = linkPathname === activePathname

      const newClassName = classnames(defaultClassname, {
        [activeClassName]: isActive,
        [nonActiveClassName]: !isActive,
      })

      if (newClassName !== className) {
        setClassName(newClassName)
      }
    }
  }, [
    asPath,
    isReady,
    props.as,
    props.href,
    defaultClassname,
    activeClassName,
    nonActiveClassName,
    setClassName,
    className,
  ])

  return (
    <Link className={className} {...props}>
      {children}
    </Link>
  )
}

export default ActiveLink
