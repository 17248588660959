import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link/ActiveLink'
import classnames from 'classnames'

export type NavBarMenuTypeCustomItem = {
  type: 'divider'
  isDesktop?: boolean
  isMobile?: boolean
  isGuestOnly?: boolean
  isAuthOnly?: boolean
}

export type NavBarMenuTypeItem =
  | {
      href: string
      label: string
      type?: 'link'
      isDesktop?: boolean
      isMobile?: boolean
      isGuestOnly?: boolean
      isAuthOnly?: boolean
    }
  | NavBarMenuTypeCustomItem

export type NavBarMenuType = {
  items: NavBarMenuTypeItem[]
  onItemClick?: () => void
  itemClassname?: string
  itemActiveClassname?: string
  itemNonActiveClassname?: string
  className?: string
  isGuest: boolean
  isAuthLoading: boolean
}

const NavBarMenu = ({
  items,
  onItemClick,
  itemClassname,
  className,
  isGuest,
  isAuthLoading,
  itemActiveClassname = 'text-black',
  itemNonActiveClassname = 'text-gray-400 hover:text-black',
}: NavBarMenuType) => {
  return (
    <div className={classnames('flex duration-200 flex-col sm:flex-row sm:space-x-6', className)}>
      {items.map((e, key) => {
        if (e.isGuestOnly === true && (isAuthLoading || isGuest === false)) {
          return null // hide for non-guests
        }

        if (e.isAuthOnly === true && (isAuthLoading || isGuest === true)) {
          return null // hide for guests
        }

        const visibilityClassname = {
          'sm:hidden': e.isMobile === true,
          'hidden sm:block': e.isDesktop === true,
        }

        if (e.type === 'divider') {
          return (
            <div key={`divider-${key}`} className={classnames('w-full px-5', visibilityClassname)}>
              <div className="border-b border-gray-100 w-full my-3" />
            </div>
          )
        }

        return (
          <Link
            key={key}
            href={e.href}
            activeClassName={itemActiveClassname}
            nonActiveClassName={itemNonActiveClassname}
            className={classnames(
              'duration-200 text-sm font-medium',
              visibilityClassname,
              itemClassname,
            )}
            onClick={onItemClick}
          >
            {e.label}
          </Link>
        )
      })}
    </div>
  )
}

NavBarMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string,
      isDesktop: PropTypes.bool,
      isMobile: PropTypes.bool,
      type: PropTypes.string,
    }),
  ),
  onItemClick: PropTypes.func,
  itemClassname: PropTypes.string,
  itemActiveClassname: PropTypes.string,
  itemNonActiveClassname: PropTypes.string,
  className: PropTypes.string,
  isGuest: PropTypes.bool,
  isAuthLoading: PropTypes.bool,
}

export default NavBarMenu
