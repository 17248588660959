import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

type ComponentProps = {
  children?: React.ReactNode
  href: string
  className?: any
}

const Component = ({ href, children, className }: ComponentProps) => {
  return (
    <a className={classNames(className)} href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}

Component.propTypes = {
  children: PropTypes.any,
  href: PropTypes.string,
  className: PropTypes.any,
}

export default Component
