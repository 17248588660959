import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

type CircleLabelProps = {
  children?: React.ReactNode
  classNames?: any
  isInfo?: boolean
  isOptional?: boolean
  isPurple?: boolean
  isSuccess?: boolean
  isPink?: boolean
  isOrange?: boolean
}

const CircleLabel = ({
  children,
  classNames,
  isInfo,
  isOptional,
  isPurple,
  isSuccess,
  isPink,
  isOrange,
}: CircleLabelProps) => {
  return (
    <span
      className={classnames(
        'px-3 py-1 rounded-3xl',
        {
          'bg-sky-100 text-sky-500': isInfo === true,
          'bg-gray-100 text-gray-500': isOptional === true,
          'bg-purple-100 text-purple-500': isPurple === true,
          'bg-green-100 text-green-500': isSuccess === true,
          'bg-pink-100 text-pink-500': isPink === true,
          'bg-orange-100 text-orange-500': isOrange === true,
        },
        classNames,
      )}
    >
      {children}
    </span>
  )
}

CircleLabel.propTypes = {
  children: PropTypes.any,
  classNames: PropTypes.string,
  isInfo: PropTypes.bool,
  isOptional: PropTypes.bool,
  isPurple: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isPink: PropTypes.bool,
  isOrange: PropTypes.bool,
}

export default CircleLabel
