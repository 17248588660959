import React from 'react'
import Logo from 'components/Logo'
import LogoBeans from 'components/Logo/Beans'

const NavBarLogo = () => {
  return (
    <>
      <Logo showVersion className={'hidden sm:block'} />
      <LogoBeans width={50} height={50} className={'sm:hidden'} />
    </>
  )
}

export default NavBarLogo
