import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type NavBarMobileButtonType = {
  onClick: () => void
}

const NavBarMobileButton = ({ onClick }: NavBarMobileButtonType) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classnames({
        'inline-flex cursor-pointer items-center justify-center p-2 rounded-md focus:outline-none':
          true,
        'transition delay-100 duration-300 ease-in-out': true,
        'text-gray-400': true,
      })}
      aria-expanded="false"
    >
      <FontAwesomeIcon size="2x" icon={['fal', 'bars']} />
    </button>
  )
}

export default NavBarMobileButton
