import React from 'react'
import classNames from 'classnames'

type GradientLabelProps = {
  children?: React.ReactNode
  isGradient?: boolean
  isGreen?: boolean
  isBlue?: boolean
  isPink?: boolean
  viceVersa?: boolean
  className?: string
}

const GradientLabel = ({
  children,
  viceVersa,
  isGradient,
  isGreen,
  isPink,
  isBlue,
  className,
}: GradientLabelProps) => {
  return (
    <span
      className={classNames(
        {
          'tracking-tighter pr-1 bg-clip-text text-transparent bg-gradient-to-r': true,
          'from-fuchsia-500 via-orange-500 to-fuchsia-500': isGradient,
          'from-teal-500 to-green-500': isGreen,
          'from-cyan-500 to-blue-500': isBlue,
          'from-fuchsia-500 to-rose-500': isPink,
        },
        className,
      )}
    >
      {children}
    </span>
  )
}

export default GradientLabel
