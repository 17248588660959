import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'utils/dayjs'
import { useTranslation } from 'react-i18next'

import ROUTES, { EXTERNAL_HREFS } from 'config/routes'

import Logo from 'components/Logo'
import Link from 'components/Link'
import ExternalLink from 'components/Link/ExternalLink'

type ComponentProps = {}

const Component = ({}: ComponentProps) => {
  const { t } = useTranslation('common')

  const headerClass = 'mb-4 text-2xl font-bold'
  const listItemClass = 'mb-4'
  const linkClass = 'text-gray-800 hover:text-gray-600'

  return (
    <section className="bg-gray-50 relative">
      {true && (
        <div className="flex w-full opacity-10">
          <div className="w-1/3 flex">
            <div className="w-1/3 py-1 bg-green-400"></div>
            <div className="w-1/3 py-1 bg-orange-400"></div>
            <div className="w-1/3 py-1 bg-pink-400"></div>
          </div>

          <div className="w-1/3 flex">
            <div className="w-1/3 py-1 bg-green-400"></div>
            <div className="w-1/3 py-1 bg-orange-400"></div>
            <div className="w-1/3 py-1 bg-pink-400"></div>
          </div>

          <div className="w-1/3 flex">
            <div className="w-1/3 py-1 bg-green-400"></div>
            <div className="w-1/3 py-1 bg-orange-400"></div>
            <div className="w-1/3 py-1 bg-pink-400"></div>
          </div>
        </div>
      )}

      <section>
        <div className="py-16 md:py-20 bg-gradient radius-for-skewed">
          <div className="container mx-auto px-8">
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/3 mb-16 lg:mb-0">
                <div className="items-center inline-block">
                  <Logo width={215} height={86} showCommit />
                </div>

                <div className="mb-4 max-w-sm text-gray-600 leading-loose">
                  {t('footer.description')}
                </div>
              </div>

              <div className="w-full lg:w-2/3 lg:pl-16 flex flex-wrap justify-between">
                <div className="w-full md:w-1/3 lg:w-auto mb-16 md:mb-0">
                  <h3 className={headerClass}>{t('footer.firstCol.title')}</h3>

                  <ul>
                    <li className={listItemClass}>
                      <Link className={linkClass} href={ROUTES.explore}>
                        {t('footer.firstCol.explore')}
                      </Link>
                    </li>

                    <li className={listItemClass}>
                      <Link className={linkClass} href={ROUTES.forBusiness}>
                        {t('footer.firstCol.forBusiness')}
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="w-full md:w-1/3 lg:w-auto mb-16 md:mb-0">
                  <h3 className={headerClass}>{t('footer.secondCol.title')}</h3>

                  <ul>
                    <li className={listItemClass}>
                      <Link className={linkClass} href={ROUTES.blog}>
                        {t('footer.secondCol.blog')}
                      </Link>
                    </li>

                    <li className={listItemClass}>
                      <Link className={linkClass} href={ROUTES.terms}>
                        {t('footer.secondCol.terms')}
                      </Link>
                    </li>

                    <li className={listItemClass}>
                      <Link className={linkClass} href={ROUTES.privacy}>
                        {t('footer.secondCol.privacy')}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="w-full md:w-1/3 lg:w-auto">
                  <h3 className={headerClass}>{t('footer.thirdCol.title')}</h3>

                  <ul>
                    <li className={listItemClass}>
                      <ExternalLink className={linkClass} href={EXTERNAL_HREFS.github}>
                        {t('footer.thirdCol.github')}
                      </ExternalLink>
                    </li>

                    <li className={listItemClass}>
                      <ExternalLink className={linkClass} href={EXTERNAL_HREFS.discord}>
                        {t('footer.thirdCol.discord')}
                      </ExternalLink>
                    </li>

                    <li className={listItemClass}>
                      <ExternalLink className={linkClass} href={EXTERNAL_HREFS.twitter}>
                        {t('footer.thirdCol.twitter')}
                      </ExternalLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <p className="lg:text-center text-xs text-gray-400 pt-0 md:pt-12 mt-16">
              © {dayjs().year()}. {t('footer.rightsReserved')}
            </p>
          </div>
        </div>
      </section>
    </section>
  )
}

Component.propTypes = {
  //
}

export default Component
