import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

// Components
import Link from 'components/Link'
import UserMenu from 'components/UserMenu'

// Local components
import NavBarLogo from './NavBarLogo'
import NavBarMenu, { NavBarMenuTypeItem } from './NavBarMenu'
import NavBarMobileButton from './NavBarMobileButton'

// Utils
import ROUTES from 'config/routes'
import useAuth from 'hooks/useAuth'

type NavBarType = {
  leftItems: NavBarMenuTypeItem[]
  navClassnames?: string
  navChildClassnames?: string
  menuClassnames?: string
  isSticky?: boolean
  hasBorderBottom?: boolean
  isWhite?: boolean
  isSmallCircles?: boolean
  leftItemsClassname?: string
  leftItemsActiveClassname?: string
  leftItemsNonActiveClassname?: string
}

const NavBar = ({
  leftItems,
  navClassnames,
  navChildClassnames,
  menuClassnames,
  isSticky,
  hasBorderBottom = true,
  isWhite = true,
  isSmallCircles = false,
  leftItemsClassname,
  leftItemsActiveClassname,
  leftItemsNonActiveClassname,
}: NavBarType) => {
  const { t } = useTranslation('common')

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const { isAuthLoading, isGuest } = useAuth()

  return (
    <nav
      className={classnames(navClassnames, {
        'bg-white __mobile-menu-open': isMobileMenuOpen,
        'bg-white': isWhite && isMobileMenuOpen === false,
        'bg-small-circles': isSmallCircles && isMobileMenuOpen === false,
        'border-b border-gray-100': hasBorderBottom,
        'md:fixed md:top-0 md:left-0 md:right-0 md:z-50': isSticky,
      })}
    >
      <div className={classnames('mx-auto px-2 sm:px-6 lg:px-8', navChildClassnames)}>
        <div className={classnames('relative flex items-center justify-between', menuClassnames)}>
          {leftItems && (
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <NavBarMobileButton onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} />
            </div>
          )}

          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="shrink-0 flex items-center">
              <NavBarLogo />
            </div>

            {leftItems && (
              <div className="hidden sm:flex sm:items-center sm:ml-12">
                <NavBarMenu
                  isGuest={isGuest}
                  isAuthLoading={isAuthLoading}
                  itemClassname={leftItemsClassname}
                  itemActiveClassname={leftItemsActiveClassname}
                  itemNonActiveClassname={leftItemsNonActiveClassname}
                  items={leftItems}
                />
              </div>
            )}
          </div>

          {isAuthLoading === false && (
            <div className={'absolute inset-y-0 right-0 flex items-center'}>
              {isGuest ? (
                <div className={'hidden sm:block'}>
                  <Link href={ROUTES.signin} className={'btn btn-sm btn-gray mr-2'}>
                    {t('navbar.loginLink')}
                  </Link>

                  <Link href={ROUTES.signup} className={'btn btn-sm btn-primary'}>
                    {t('navbar.signupLink')}
                  </Link>
                </div>
              ) : (
                <UserMenu isGray />
              )}
            </div>
          )}
        </div>
      </div>

      {leftItems && (
        <div
          className={classnames({
            'slide-down border-b border-gray-100': isMobileMenuOpen,
            'slide-up': isMobileMenuOpen === false,
          })}
        >
          <NavBarMenu
            onItemClick={() => {
              setIsMobileMenuOpen(false)
            }}
            isGuest={isGuest}
            isAuthLoading={isAuthLoading}
            itemClassname="mx-2 px-3 py-3"
            className="mb-2"
            items={leftItems}
          />
        </div>
      )}
    </nav>
  )
}

NavBar.propTypes = {
  navClassnames: PropTypes.any,
  navChildClassnames: PropTypes.any,
  menuClassnames: PropTypes.any,
  isSticky: PropTypes.bool,
  hasBorderBottom: PropTypes.bool,
  isWhite: PropTypes.bool,
  isSmallCircles: PropTypes.bool,

  leftItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string,
      isDesktop: PropTypes.bool,
      isMobile: PropTypes.bool,
    }),
  ),

  leftItemsClassname: PropTypes.string,
  leftItemsActiveClassname: PropTypes.string,
  leftItemsNonActiveClassname: PropTypes.string,
}

export default NavBar
