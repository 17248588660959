import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ROUTES from 'config/routes'
import { SITE_STAGE } from 'config/constants'
import Image from 'next/image'
import Link from 'components/Link'

type LogoType = {
  height?: number
  width?: number
  className?: any
  showVersion?: boolean
  showCommit?: boolean
}

const Logo = ({ height = 66, width = 165, className, showVersion, showCommit }: LogoType) => {
  const versionLabelStyle = {
    right: '10px',
    top: '5px',
  }

  const commitStyle = {
    right: '10px',
    top: '5px',
  }

  return (
    <Link
      href={ROUTES.home}
      className={classnames('flex items-center justify-center cursor-pointer relative', className)}
    >
      <div className={classnames('hover:hue-rotate-180 duration-200')}>
        <Image
          quality={100}
          src="/logo/combined/solana.png"
          alt="Logo"
          width={width}
          height={height}
        />
      </div>

      {showVersion && (
        <span
          className={'absolute text-xs font-light tracking-tight text-gray-600'}
          style={versionLabelStyle}
        >
          {SITE_STAGE}
        </span>
      )}

      {showCommit && (
        <span
          className={'absolute text-xs font-light tracking-tight text-gray-600'}
          style={commitStyle}
        >
          {(process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || 'dev1234').substring(0, 6)}
        </span>
      )}
    </Link>
  )
}

Logo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.any,
  showVersion: PropTypes.bool,
  showCommit: PropTypes.bool,
}

export default Logo
